<template>
    <div class="main">
        <img src="../../assets/shenqing_shenhezhong.png" alt="" />
        <div class="text1">{{ $t('zi-liao-shen-he-zhong') }}</div>
        <div class="text2">
            {{
                $t(
                    'nin-de-hui-yuan-ru-zhu-shen-qing-ti-jiao-cheng-gong-ping-tai-jiang-yu-7-ge-gong-zuo-ri-nei-fan-kui-gan-xie-nin-de-xin-ren'
                )
            }}
        </div>
        <!-- <el-button type="warning" size="small" @click="$router.go(-1)">返回</el-button> -->
        <el-button type="warning" size="small" @click="$router.push({ path: '/home' })">
            {{ $t('fan-hui') }}
        </el-button>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: '',
    data() {
        return {};
    },
    computed: {
        ...mapState(['userInfo'])
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.main {
    // min-height: calc(max(100vh, 1000px) - 90px - 376px - 174px);

    @include max-height(640px, $max: 1200px);

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    img {
        display: block;
        width: 103px;
        height: 78px;
    }
}

.text1 {
    font-size: 20px;
    font-weight: bold;
    color: #292c33;
    line-height: 28px;
    letter-spacing: 1px;
    padding: 22px 0 15px;
}

.text2 {
    font-size: 13px;
    color: #939599;
    line-height: 18px;
    max-width: 300px;
    text-align: center;
}

.el-button {
    margin-top: 60px;
    min-width: 222px;
    height: 40px;
}
</style>
